@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

@import "colors";
@import "buttons";
@import "inputs";
@import "main";
@import "modal";

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  font-family: 'Manrope', serif;
  overflow-x: hidden;
  margin: 0;
}

:root {
  font-size: 10px;
}

h1, h2, h3, h4, h5, h6, p, ul, li {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
  outline: none;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

a {
  text-decoration: none;
  cursor: pointer;
}

.main {
  min-width: 100vw;
  min-height: 100vh;
}