@import "colors";

.modal-bg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  backdrop-filter: blur(0px);
  z-index: -10;
  opacity: 0;
  transition: .3s opacity;
  &.active {
    z-index: 100;
    opacity: 1;
    backdrop-filter: blur(3px);
  }
}

.modal {
  width: 100%;
  max-width: 450px;
  padding: 16px 24px;
  background: $light-color;
  box-shadow: 0 0 50px 0 rgba(2, 54, 54, 0.12);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  z-index: 200;
  border-radius: 12px;
  transition: .3s all;
  &__title {
    font-size: 2.4rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
    @media screen and (max-width: 525px){
      font-size: 1.8rem;
    }
  }
  &__description {
    font-size: 1.6rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px;
    @media screen and (max-width: 525px){
      font-size: 1.4rem;
    }
  }
  .btn {
    margin: 16px auto 0;
  }

  &.active {
    transform: translate(-50%, -50%) scale(1);
  }
}