
$accent-color: #056D6C;
$light-color: #ffffff;
$category-bg: #76BFDE;


//Buttons
$accent-hover: #2B8382;
$accent-active: #086160;
$accent-disable: #69A7A7;

//Text
$dark-text: #2A3F3E;
$light-dart-color: #607575;
$middle-text: #949E9E;

//Inputs
$border-input: #E8E8E8;
$input-text: #2A3F3E;
$placeholder: #949E9E;
$input-hover: #EBF2F1;
$input-focus: #0F4D4D;
$input-error: #C96073;
$select-bg: #ecf2f2;

//Status
$success-bg: #D7E8E7;
$success-text: #056D6C;
$warning-bg: #E8E8E8;
$warning-text: #454545;
$danger-bg: #FFE3E8;
$danger-text: #FF5070;
