@import "colors";

.form {
  width: 100%;
  max-width: 680px;
  padding: 0 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &__logo {
    width: 100%;
    max-width: 600px;
    display: block;
    margin: 0 auto 8px;
  }

  &__headline {
    font-size: 2.4rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
    @media screen and (max-width: 525px){
      font-size: 1.8rem;
    }
  }

  &__description {
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px;
    @media screen and (max-width: 525px){
      font-size: 1.4rem;
    }
  }

  &__block {
    padding: 16px 24px;
    border-radius: 12px;
    background: $light-color;
    box-shadow: 0 0 50px 0 rgba(2, 54, 54, 0.12);
    display: flex;
    align-items: center;
    @media screen and (max-width: 525px){
      flex-direction: column;
    }
  }

  &__block-left {
    width: 60%;
    padding-right: 24px;
    border-right: 1px solid $border-input;
    @media screen and (max-width: 525px){
      width: 100%;
      border-right: none;
      padding-right: 0;
      margin-bottom: 32px;
    }

    .input-block {
      margin-bottom: 16px;
    }

    .btn {
      width: 100%;
    }
  }

  &__block-right {
    width: 40%;
    padding-left: 24px;
    @media screen and (max-width: 525px){
      width: 100%;
      padding-left: 0;
    }

    .title.title {
      margin-bottom: 4px;
    }
  }

  .title {
    font-size: 1.6rem;
    font-weight: 600;
    color: $light-dart-color;
    margin-bottom: 16px;
    @media screen and (max-width: 525px){
      font-size: 1.4rem;
    }
  }

  .mail {
    font-size: 2.2rem;
    font-weight: 700;
    color: $dark-text;
    @media screen and (max-width: 525px){
      font-size: 1.8rem;
    }
  }
}