@import "colors";


.btn {
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  border-radius: 12px;
  font-size: 2rem;
  font-weight: 600;
  @media screen and (max-width: 525px){
    font-size: 1.8rem;
  }

  &.btn-accent {
    background-color: $accent-color;
    color: $light-color;

    &:hover {
      background-color: $accent-hover;
    }

    &:active {
      background-color: $accent-active;
    }

    &:disabled {
      background-color: $accent-disable;
    }
  }

  &.btn-light {
    background-color: $light-color;
    color: $accent-color;
    box-shadow: 0 0 50px 0 rgba(2, 54, 54, 0.12);
  }

  &.btn-icon {
    img {
      width: 24px;
      height: 24px;
      object-fit: contain;
      margin-right: 10px;
      margin-left: 0;
    }

    &.right-icon {
      img {
        margin-right: 0;
        margin-left: 10px;
      }
    }
  }

  &.btn-link {
    font-weight: 400;
    color: $dark-text;
  }

  &.small-size-btn {
    width: 51px;
    height: 51px;
    justify-content: center;
    img {
      margin: 0;
    }
  }
}
